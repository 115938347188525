@use '@angular/material' as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin overview-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $changes-color: map-get($theme, changes-color);

    .app-overview {
        display: flex;
        justify-content: center;

        &__action-icon {
            @if $is-dark {
                color: mat.get-color-from-palette($primary, A400);
            } @else {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__edit-dialog {
            &__title {
                font-size: 18px !important;
                font-weight: normal !important;
                margin-bottom: 32px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
            }

            &__actions {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 12px;

                button {
                    margin-right: 16px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        &__patient-header {
            &:hover {
                // rewriting bad angular-material style
                background: transparent !important;
            }

            & > .mat-content {
                justify-content: space-between;
            }

            .mat-content > .mat-expansion-panel-header-title,
            .mat-content > .mat-expansion-panel-header-description {
                flex: 0 0 auto;
            }

            .mat-expansion-indicator {
                margin-top: -6px;
            }

            &.mat-expanded {
                .mat-expansion-indicator {
                    margin-bottom: -10px;
                }
            }
        }

        &__patient {
            @if $is-dark {
                background: mat.get-color-from-palette($primary, 600);
            }

            &--changed {
                color: $changes-color;
            }
        }

        &__card {
            @if $is-dark {
                background: mat.get-color-from-palette($primary, 600);
            }

            &-icon {
                @if $is-dark {
                    color: rgba(255, 255, 255, 0.72);
                } @else {
                    color: mat.get-color-from-palette($primary);
                }
            }

            .mat-card-header-text {
                margin: 0;
            }

            &--changed {
                color: $changes-color;
            }
        }

        &__list {
            &-item {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                background-color: transparent !important;

                @if $is-dark {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                }

                &.mat-mdc-list-item .mdc-list-item__content {
                    padding: 0;
                }

                &.mat-list-item-disabled {
                    .app-overview__list-add {
                        @extend .text-muted;
                    }
                }
            }

            &-add {
                color: mat.get-color-from-palette($primary, 500);

                @if $is-dark {
                    color: mat.get-color-from-palette($primary, A400);
                }
            }

            &-text--secondary {
                color: rgba(0, 0, 0, 0.6);

                @if $is-dark {
                    color: rgba(255, 255, 255, 0.72);
                }
            }

            &-text--changed {
                color: $changes-color;

                .app-overview__list-text--secondary {
                    color: rgba($changes-color, 0.72);
                }
            }
        }

        .icon-changed {
            color: $changes-color;
        }

        .icon-error {
            color: $pdms-background-color-warning;
        }
    }
}
