@use '@angular/material' as mat;
//
@import 'screens/overview/overview.component.theme';
@import 'screens/balance/balance.theme';

@mixin mona-encounter-theme($theme) {
    @include overview-theme($theme);
    @include balance-theme($theme);

    $accent: map-get($theme, accent);
    $review-bgc: map-get($theme, review-bgc);

    .review-bgc {
        background-color: $review-bgc;
    }

    .has-changes {
        color: mat.get-color-from-palette($accent);
    }

    .has-removed {
        text-decoration: line-through;
    }
}
