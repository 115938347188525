@use '@angular/material' as mat;

@mixin custom-mat-menu-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $success: map-get($theme, success);
    $is-dark: map-get($theme, is-dark);

    .mat-mdc-menu-content {
        & > * {
            letter-spacing: normal !important;
        }

        &:not(:empty) {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &:has(.medication-autocomplete__confirm-card) {
            padding: 0px;
        }

        .mat-mdc-menu-item .mat-mdc-menu-item-text {
            letter-spacing: normal;
            font-size: 14px;
        }
    }
}
